<template>
  <v-container
    id="extended-forms"
    fluid
    tag="section"
  >
    <v-row v-if="$auth.check('customer/cloud/create')">
      <v-col cols="12">
        <base-material-card
          color="yellow darken-1"
          icon="mdi-upload-multiple"
        >
          <template #after-heading>
            <div class="display-2 font-weight-light">
              {{ $tc('upload', 2) }}
            </div>
          </template>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-data-table
                :items="uploadable.data"
                :headers="uploadable.headers"
                :footer-props="importable.footer"
                :items-per-page="importable.options.itemsPerPage"
                dense
                :loading="uploadable.loading"
              >
                <template #top>
                  <div
                    class="mt-2 text-right"
                    style="margin-bottom: 2px;"
                  >
                    <v-btn
                      color="grey darken-2"
                      class="float-right"
                      small
                      :disabled="selected.disabled || uploadable.data.length < 1"
                      @click="uploadableFiles = []; uploadable.data = []"
                    >
                      {{ $tc("reset", 1) }}
                    </v-btn>
                    <v-btn
                      color="grey darken-2"
                      class="float-right ml-4"
                      small
                      :disabled="selected.disabled || uploadable.data.length < 1 || importable.data.length >= importable.max || tracks.data.length >= tracks.max"
                      :loading="uploadable.loading"
                      @click="uploadFiles()"
                    >
                      {{ $tc("upload", 1) }} ({{ uploadable.data.length }})
                    </v-btn>
                    <v-file-input
                      v-model="selected.data"
                      :disabled="selected.disabled || uploadable.data.length >= uploadable.max"
                      :placeholder="$tc('fileselect', 1) + ' (max ' + uploadable.max + ')'"
                      accept="audio/mpeg"
                      multiple
                      prepend-icon="mdi-paperclip"
                      color="grey darken-2"
                      dense
                      @change="addUploadable()"
                    />
                  </div>
                </template>

                <template #progress>
                  <v-progress-linear
                    color="primary"
                    absolute
                    :value="uploadable.progress"
                  />
                </template>

                <template #[`item.actions`]="{ item }">
                  <v-icon
                    small
                    :disabled="selected.disabled"
                    @click="deleteUploadable(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-data-table
                :items="importable.data"
                :headers="importable.headers"
                :footer-props="importable.footer"
                :items-per-page="importable.options.itemsPerPage"
                dense
                :loading="importable.loading"
              >
                <template #top>
                  <div class="mt-2 mb-4 text-right">
                    <v-btn
                      small
                      color="primary"
                      :disabled="selected.disabled || importable.data.length < 1 || tracks.data.length >= tracks.max"
                      :loading="importable.loading"
                      @click="importFiles()"
                    >
                      <v-icon left>
                        mdi-forward
                      </v-icon>
                      {{ $tc("process", 1) }} ({{ importable.data.length }})
                    </v-btn>
                    <v-btn
                      dark
                      small
                      color="primary"
                      @click="getImportables()"
                    >
                      <v-icon dark>
                        mdi-cached
                      </v-icon>
                    </v-btn>
                  </div>
                </template>

                <template #[`item.title`]="props">
                  <span
                    v-if="props.item.deleted === true"
                    class="text-decoration-line-through grey--text"
                  >
                    {{ props.item.title }}
                  </span>
                  <v-edit-dialog
                    v-else
                    :return-value.sync="props.item.title"
                  >
                    <span v-if="props.item.title === ''">???</span>
                    <span v-else>{{ props.item.title }}</span>
                    &nbsp;
                    <v-icon x-small>
                      mdi-pencil
                    </v-icon>
                    <template #input>
                      <v-text-field
                        v-model="props.item.title"
                        :label="$tc('title', 1)"
                        :placeholder="$tc('title', 1)"
                        single-line
                      />
                    </template>
                  </v-edit-dialog>
                </template>

                <template #[`item.artist`]="props">
                  <span
                    v-if="props.item.deleted === true"
                    class="text-decoration-line-through grey--text"
                  >
                    {{ props.item.artist }}
                  </span>
                  <v-edit-dialog
                    v-else
                    :return-value.sync="props.item.artist"
                  >
                    <span v-if="props.item.artist === ''">???</span>
                    <span v-else>{{ props.item.artist }}</span>
                    &nbsp;
                    <v-icon x-small>
                      mdi-pencil
                    </v-icon>
                    <template #input>
                      <v-text-field
                        v-model="props.item.artist"
                        :label="$tc('artist', 1)"
                        :placeholder="$tc('artist', 1)"
                        single-line
                      />
                    </template>
                  </v-edit-dialog>
                </template>

                <template #[`item.actions`]="{ item }">
                  <v-icon
                    v-if="item.deleted === false"
                    small
                    :disabled="selected.disabled"
                    @click="deleteImportable(item, true)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    v-else
                    small
                    :disabled="selected.disabled"
                    @click="deleteImportable(item, false)"
                  >
                    mdi-delete-restore
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <base-material-card
          color="yellow darken-1"
          icon="mdi-folder-music"
        >
          <template #after-heading>
            <div class="display-2 font-weight-light">
              {{ $tc('music', 1) }}
            </div>
          </template>

          <v-data-table
            :items="tracks.data"
            :headers="tracks.headers"
            :footer-props="tracks.footer"
            :items-per-page="tracks.options.itemsPerPage"
            :loading="tracks.loading"
            :search.sync="tracks.search"
            dense
          >
            <template #top>
              <v-btn
                class="float-right ml-2"
                dark
                small
                color="primary"
                @click="getTracks()"
              >
                <v-icon dark>
                  mdi-cached
                </v-icon>
              </v-btn>

              <v-text-field
                v-model="tracks.search"
                append-icon="mdi-magnify"
                :label="$tc('search', 1)"
                hide-details
                single-line
                style="max-width: 250px;"
              />
              <v-divider class="mt-5" />
            </template>

            <template #[`item.official`]="{ item }">
              <span v-if="item.official === 1">{{ $tc("eazis", 1) }}</span>
              <span v-else>{{ $tc("upload", 1) }}</span>
            </template>

            <template #[`item.user.name`]="{ item }">
              <span v-if="item.user">{{ item.user.name }}</span>
              <span v-else>[{{ $tc("deleted", 1) }}]</span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-icon
                v-if="item.user"
                small
                :disabled="item.user.id !== $auth.user().id && !$auth.check(['customer/cloud/delete'])"
                @click="deleteTrack(item)"
              >
                mdi-delete
              </v-icon>
              <v-icon
                v-else
                small
                :disabled="!$auth.check(['customer/cloud/delete'])"
                @click="deleteTrack(item)"
              >
                mdi-delete
              </v-icon>
            </template>

            <v-data-footer :items-per-page-options="[4, 16, 64, 256, 1024]" />
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'MusicUploads',

    data: function () {
      return {
        tracks: {
          data: [],
          headers: [
            {
              text: this.$tc('title', 1),
              value: 'mp3.title',
            },
            {
              text: this.$tc('artist', 1),
              value: 'mp3.artist',
            },
            {
              text: this.$tc('playtime', 1),
              value: 'mp3.playtime',
            },
            {
              text: this.$tc('source', 1),
              value: 'official',
            },
            {
              text: this.$tc('user', 1),
              value: 'user.name',
            },
            {
              text: this.$tc('action', 2),
              value: 'actions',
              align: 'end',
            },
          ],
          footer: {
            itemsPerPageAllText: '-',
            itemsPerPageOptions: [25, 50, 100, -1],
            itemsPerPageText: '',
            pageText: '{0}-{1} / {2}',
          },
          options: {
            itemsPerPage: 100,
          },
          search: '',
          loading: true,
          max: 5000,
        },
        selected: {
          data: [],
          disabled: false,
        },
        uploadableFiles: [],
        uploadable: {
          data: [],
          headers: [
            {
              text: this.$tc('file', 1),
              value: 'fileObj.name',
            },
            {
              text: this.$tc('size', 1),
              value: 'fileObj.size',
            },
            {
              text: this.$tc('action', 2),
              value: 'actions',
              align: 'end',
            },
          ],
          footer: {
            itemsPerPageAllText: '-',
            itemsPerPageOptions: [25, 50, -1],
            itemsPerPageText: '',
            pageText: '{0}-{1} / {2}',
          },
          options: {
            itemsPerPage: 25,
          },
          loading: false,
          progress: 0,
          max: 100,
        },
        importable: {
          data: [],
          headers: [
            {
              text: this.$tc('title', 1),
              value: 'title',
            },
            {
              text: this.$tc('artist', 1),
              value: 'artist',
            },
            {
              text: this.$tc('action', 2),
              value: 'actions',
              align: 'end',
            },
          ],
          footer: {
            itemsPerPageAllText: '-',
            itemsPerPageOptions: [25, 50, 100, -1],
            itemsPerPageText: '',
            pageText: '{0}-{1} / {2}',
          },
          options: {
            itemsPerPage: 25,
          },
          loading: false,
          max: 1000,
        },
      }
    },
    watch: {
      uploadableFiles: function (val) {
        if (val.length === 0) {
          this.getImportables()
          this.uploadable.data = []
          this.uploadable.loading = false
          this.uploadable.progress = 0
          this.selected.disabled = false
        }
      },
    },
    mounted () {
      this.getTracks()
      this.getImportables()
    },
    methods: {
      getTracks () {
        var self = this
        self.tracks.loading = true
        var s = ''
        if (self.tracks.search.length >= 2) s = '&search=' + self.tracks.search
        self.tracks.data = []
        self.$http.get('/api/webupdate/usbcloud?perPage=25&include=mp3,user' + s).then(function (response) {
          self.tracks.data = response.data
          self.tracks.loading = false
        }, function (response) {
          self.tracks.loading = false
        })
      },
      deleteTrack (item) {
        var self = this
        self.tracks.loading = true
        self.$http.delete('/api/webupdate/usbcloud/delete/' + item.id).then(function (response) {
          const index = self.tracks.data.indexOf(item)
          self.tracks.data.splice(index, 1)
          self.tracks.loading = false
        }, function (response) {
          self.tracks.loading = false
        })
      },
      addUploadable () {
        var self = this
        for (var i = 0; i < self.selected.data.length; i++) {
          if (self.uploadable.data.length < self.uploadable.max) {
            var file = self.selected.data[i]
            var duplicate = self.uploadable.data.find(o => o.fileObj.name === file.name)
            if (!duplicate) {
              var formData = new window.FormData()
              formData.append('track', file)
              var upload = {
                fileObj: file,
                formObj: formData,
                progressInt: 0,
              }
              self.uploadable.data.push(upload)
              self.uploadableFiles.push(upload)
            }
          }
        }
        /* self.uploadableFiles = [
          ...self.selectedFiles,
          ...self.uploadableFiles,
        ] */
        self.selected.data = []
      },
      deleteUploadable (item) {
        var index = this.uploadable.data.indexOf(item)
        this.uploadable.data.splice(index, 1)
        index = this.uploadableFiles.indexOf(item)
        this.uploadableFiles.splice(index, 1)
      },
      uploadFiles () {
        var self = this
        self.selected.disabled = true
        self.uploadable.loading = true
        self.uploadable.progress = 0
        var x = self.uploadable.data.length
        self.disabled = true
        for (var i = 0; i < self.uploadable.data.length; i++) {
          self.$http.post(
            '/api/webupdate/usbcloud/upload',
            self.uploadable.data[i].formObj,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              /* onUploadProgress: function (progressEvent) {
                self.uploads[i].progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
              }, */
              /* onUploadProgress: function (progressEvent) {
                this.uploadableFiles[i].progressInt = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              }, */
            })
            .then(({ data }) => {
              x--
              self.uploadable.progress = (self.uploadable.data.length - x) / self.uploadable.data.length * 100
              if (x === 0) {
                self.uploadableFiles = []
              }
            }, response => {
              // self.tracks.data = [],
            })
        }
      },
      getImportables () {
        var self = this
        self.importable.loading = true
        self.importable.data = []
        self.$http.get('/api/webupdate/usbcloud/prepare').then(function (response) {
          self.importable.data = response.data
          self.importable.loading = false
        }, function (response) {
          self.importable.loading = false
        })
      },
      deleteImportable (item, toggle) {
        const index = this.importable.data.indexOf(item)
        this.importable.data[index].deleted = toggle
      },
      importFiles () {
        var self = this
        self.selected.disabled = true
        self.importable.loading = true
        self.$http.post('/api/webupdate/usbcloud/import', self.importable.data).then(function (response) {
          self.importable.data = response.data
          self.getTracks()
          self.selected.disabled = false
          self.importable.loading = false
        }, function (response) {
          self.importable.loading = false
        })
      },
    },
  }
</script>
